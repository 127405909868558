var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Admin: Taxonomies" }
      }),
      _c("gov-tabs", { attrs: { tabs: _vm.tabs } }, [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }